
import { defineComponent } from "vue";
import {
  Col,
  Row,
  Input,
  Button,
  Cascader,
  Table,
  Pagination,
  Popover,
  message,
  Switch,
  Checkbox,
  Image,
} from "ant-design-vue";
import {
  UnorderedListOutlined,
  SettingOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
  FileAddFilled,
  StopOutlined,
  ReloadOutlined,
  ColumnHeightOutlined,
  TagsFilled,
  PictureFilled,
  SyncOutlined,
  SearchOutlined,
  EditOutlined,
  DownOutlined
} from "@ant-design/icons-vue";
import {
  disableSingleProductById,
  allBrandList,
  brandDownList,
  exportSingleProductList,
  listSingleProduct,
  categoryList,
  customParameters,
} from "../../../api/terminalList.js";
import { singleProductColumns } from "@/api/fixedData.js";
import PreviewModal from "@/components/singleProduct/PreviewModal.vue";
let { Group: CheckboxGroup } = Checkbox;
export default defineComponent({
  components: {
    Col,
    Row,
    Input,
    Button,
    Cascader,
    SettingOutlined,
    ReloadOutlined,
    ColumnHeightOutlined,
    CloudUploadOutlined, //上传图标
    CloudDownloadOutlined, //下载图标
    FileAddFilled, //新建图标
    StopOutlined, //电源图标
    EditOutlined,
    TagsFilled,
    UnorderedListOutlined,
    Table,
    Pagination,
    Popover,
    PreviewModal,
    Switch,
    aCheckbox: Checkbox,
    aCheckboxGroup: CheckboxGroup,
    PictureFilled,
    SyncOutlined,
    SearchOutlined,
    Image,
    DownOutlined
  },
  data() {
    return {
      categoryOptions: [],
      brandOptions: [],
      //数据相关
      spNameOrSpCode: "",
      spBarCode: "",
      categoryName: [],
      brandName: [],
      //table的数据
      data: [],
      //table的列名字
      columns: [],
      //复选框的选择结果
      selectedRowKeys: [],
      // 当前页数
      pageCurrent: sessionStorage.getItem("SingleProductListPageNum")||1,
      // 每页数据数量
      pageSize: sessionStorage.getItem("SingleProductListPageSize") ||10,
      pageNum: sessionStorage.getItem("SingleProductListPageNum")||1,
      //总数据量
      total: 0,
      tableHeight: "400px",
      searchFlag: false,
      //拓展字段
      customData: [],
      keepCustomData: [],
      //table的布局
      scroll: { x: "100%" },
      //table的loading
      loading: true,
      //批量操作按钮加载
      batchLoading: false,
      //table的样式
      batchStyle: "middle",
    };
  },
  methods: {
    //左侧的多选列禁用筛选
    getCheckboxProps(record) {
      if (!record.status) {
        return { disabled: true };
      }
    },
    //复选结果
    onSelectChange(selectedRowKeys: any) {
      //
      this.selectedRowKeys = selectedRowKeys;
    },
    //更换每页显示数量
    onShowSizeChange(current: any, pageSize: any) {
      this.pageNum = 1;
      this.pageCurrent = 1;
      this.pageSize = pageSize;
      sessionStorage.setItem("SingleProductListPageSize", pageSize + "");
      this.getTerminal();
    },
    //品类联级选择懒加载
    loadDataCategory(selectedOptions: any) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      categoryList(targetOption.categoryCode).then((res: any) => {
        targetOption.loading = false;
        let newOptions = res.data.data.map((item) => {
          return {
            ...item,
            value: item.categoryCode,
            label: item.categoryName,
            isLeaf: !item.isHave,
          };
        });
        targetOption.children = newOptions;
        this.categoryOptions = [...this.categoryOptions];
      });
    },
    //品牌联级选择
    loadDataBrand(selectedOptions) {
      let targetOption = selectedOptions[selectedOptions.length - 1];
      targetOption.loading = true; // load options lazily
      brandDownList(targetOption.brandCode).then(() => {
        targetOption.loading = false;
        //
        let newOptions = targetOption.childBrandList.map((item) => {
          let checkIsLeaf;
          if (item.childBrandList.length) {
            checkIsLeaf = false;
          } else {
            checkIsLeaf = true;
          }
          return {
            ...item,
            value: item.brandCode,
            label: item.brandName,
            isLeaf: checkIsLeaf,
          };
        });
        targetOption.children = newOptions;
        this.brandOptions = [...this.brandOptions];
      });
    },
    //计算品牌
    convertKey(arr, level) {
      level++;
      arr.forEach((item) => {
        item.level = level;
        if (item["childBrandList"].length != 0) {
          item.level = level;
          this.convertKey(item.childBrandList, level);
        }
      });
      return arr;
    },
    //弹出Popover 进行筛选
    handelColumns() {
      (this as any).columns = [...singleProductColumns, ...this.keepCustomData];
      (this as any).columns.push(
        {
          title: "启用状态",
          slots: { customRender: "handle" },
          align: "center",
          fixed: "right",
          width: 80,
        },
        {
          title: "操作",
          slots: { customRender: "handle2" },
          align: "left",
          fixed: "right",
          width: 80,
        }
      );
    },
    inputChange(item) {
      if (item.checked) {
        (this as any).customData.forEach((element, index) => {
          if (element.key == item.key) {
            (this as any).keepCustomData.splice(index, 0, element);
          }
        });
      } else {
        for (let i = 0; i < (this as any).keepCustomData.length; i++) {
          if (!(this as any).keepCustomData[i].checked) {
            (this as any).keepCustomData.splice(i, 1);
          }
        }
      }
      this.handelColumns();
    },
    //跳转到终端详情页面
    terminalDetails(id) {
      if (id === undefined) {
        id = "new";
      }
      if (this.$route.path.includes("singleProductApp")) {
        this.$router.push(`/singleProductApp/details/${id}`);
      } else {
        (this as any).$router.push(`/masterData/singleProduct/details/${id}`);
      }
    },
    //计算终端状态
    computedStatus(status) {
      switch (status) {
        case false:
          return "禁用";
        case true:
          return "启用";
        default:
          return "系统错误";
      }
    },
    //禁用终端 单个
    disableSingleProductById(id, status) {
      status = status == "0" ? "1" : "0";
      let params = {
        ids: [id],
        status: status,
      };
      disableSingleProductById(params).then((res) => {
        if (res.data.success) {
          if (status != 0) {
            message.success("单品已启用");
          } else {
            message.warning("单品已禁用");
          }
          this.selectedRowKeys.forEach((elem, index) => {
            if (elem === id) {
              this.selectedRowKeys.splice(index, 1);
            }
          });
          this.getTerminal();
        }
      });
    },
    //批量禁用
    disableBatch(id) {
      let params = {
        ids: [...id],
        status: 0,
      };
      disableSingleProductById(params).then((res) => {
        //
        if (res.data.success) {
          this.selectedRowKeys = [];
          message.success("批量禁用成功");
        }
        // else {
        //   message.error(res.data.data);
        // }
        this.getTerminal();
      });
    },
    //获取单品列表
    getTerminal() {
      this.loading = true;
      listSingleProduct({
        spNameOrSpCode: this.spNameOrSpCode,
        spBarCode: this.spBarCode,
        categoryCode: this.categoryName[this.categoryName.length - 1],
        brandCode: this.brandName[this.brandName.length - 1],
        pageSize: this.pageSize,
        pageNum: this.pageNum,
      })
        .then((res) => {
          if (res.data.success) {
            let result = res.data.data;
            this.total = result.totalRecord;
            result.data.forEach((item) => {
              for (var attr in item.extraField) {
                item[attr] = item.extraField[attr];
              }
            });
            this.data = result.data;
            this.loading = false;
          } else {
            this.loading = false;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    //重置
    reset() {
      this.spNameOrSpCode = "";
      this.spBarCode = "";
      this.categoryName = [];
      this.brandName = [];
      this.search();
      this.searchFlag = false;
    },
    //搜索
    search() {
      this.searchFlag = true;
      this.pageNum = 1;
      this.getTerminal();
      this.pageCurrent = 1;
    },
    //跳转到导入页面
    batchImport(type = "") {
      let path = ''
      if (this.$route.path.includes("singleProductApp")) {
        path = "/singleProductApp/batchImport"
      } else {
        path = "/masterData/singleProduct/batchImport"
      }
      this.$router.push({ path, query: { type } })
    },
    //批量导出
    batchExport() {
      this.batchLoading = true;
      exportSingleProductList({
        spNameOrSpCode: this.spNameOrSpCode,
        spBarCode: this.spBarCode,
        categoryCode: this.categoryName[this.categoryName.length - 1],
        brandCode: this.brandName[this.brandName.length - 1],
      })
        .then((res) => {
          if (res.data.success) {
            window.open(res.data.data.absoluteFileUrl);
          }
          this.batchLoading = false;
        })
        .catch(() => {
          this.batchLoading = false;
        });
    },
    //预览图片
    previewPicture(url) {
      (this as any).$refs.showPreModal.showModal(url);
    },
    //计算当前是否禁用状态
    computeStatus(status) {
      if (status == 0) {
        return false;
      } else {
        return true;
      }
    },
    //计算返回的图片url值是否真实存在的图片
    computedImgurl(record) {
      if (record.imgUrl) {
        if (record.imgUrl.includes(".jp") || record.imgUrl.includes(".png")) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
  },
  watch: {
    //监听页码和页面数量变化
    pageCurrent() {
      //
      if (this.searchFlag === false) {
        this.spNameOrSpCode = "";
        this.spBarCode = "";
        this.categoryName = [];
        this.brandName = [];
      }
      this.selectedRowKeys = [];
      this.pageNum = this.pageCurrent;
      sessionStorage.setItem("SingleProductListPageNum", this.pageCurrent + "");
      this.getTerminal();
    },
  },
  mounted() {
    //获取品牌
    allBrandList().then((res: any) => {
      let level = 0;
      this.convertKey(res.data.data, level);
      let newOptions = res.data.data.map((item) => {
        let checkIsLeaf;
        if (item.childBrandList.length) {
          checkIsLeaf = false;
        } else {
          checkIsLeaf = true;
        }
        return {
          ...item,
          value: item.brandCode,
          label: item.brandName,
          isLeaf: checkIsLeaf,
        };
      });
      this.brandOptions = newOptions;
    });
    //获取一级品类
    categoryList("").then((res: any) => {
      let newOptions = res.data.data.map((item) => {
        let checkIsLeaf = item.isHave;
        return {
          ...item,
          value: item.categoryCode,
          label: item.categoryName,
          isLeaf: !checkIsLeaf,
        };
      });
      this.categoryOptions = newOptions;
      //
    });
    //获取列表信息
    this.getTerminal();
    //获取拓展字段
    customParameters("singleProduct").then((res: any) => {
      let resArray = res.data.data.map((item, index) => {
        if (item.status) {
          return {
            title: item.extraName,
            checked: true,
            dataIndex: item.extraCode,
            key: index,
            width: 120,
          };
        } else {
          return {};
        }
      });
      resArray = resArray.filter((item) => {
        return item.title;
      });
      if (resArray.length) {
        this.customData = resArray;
        (this as any).keepCustomData = [...resArray];
      }
      (this as any).columns = singleProductColumns;
      this.columns = [...this.columns, ...this.customData];
      (this as any).columns.push(
        {
          title: "启用状态",
          slots: { customRender: "handle" },
          align: "center",
          fixed: "right",
          width: 70,
        },
        {
          title: "操作",
          slots: { customRender: "handle2" },
          align: "left",
          fixed: "right",
          width: 80,
        }
      );
    });
  },
});
